export default {
  path: '/',
  name: 'faultAlarmHome',
  component: () => import(/* webpackChunkName: "layout" */'@/views/layout'),
  children: [
    {
      path: '/faultAlarm',
      name: 'faultAlarmManage',
      component: () => import(/* webpackChunkName: "data" */'@/views/faultAlarm'),
      meta: {
        title: 'faultAlarmManage',
        icon: 'faultAlarmManage',
        parent: '/faultAlarmManage',
        userRouter: true,
        index: 3
      },
      children: [
      /** 热表最新故障 */
      {
        path: '/faultAlarm/hotMeterLast',
        name: 'hotMeterLastFault',
        component: () => import(/* webpackChunkName: "hotMeterLastFault" */'@/views/faultAlarm/hotMeter/lastFaultAlarm.vue'),
        meta: {
          keepAlive: true,
          title: 'hotMeterLastFault',
          icon: 'data',
          parent: '/faultAlarm',
          subMenu: 'hotData'
        }
      },
      /** 热表故障 */
        {
          path: '/faultAlarm/hotMeter',
          name: 'hotMeterFault',
          component: () => import(/* webpackChunkName: "hotMeterFault" */'@/views/faultAlarm/hotMeter'),
          meta: {
            keepAlive: true,
            title: 'hotMeterFault',
            icon: 'data',
            parent: '/faultAlarm',
            subMenu: 'hotData'
          }
        },
        /** 温控阀最新故障 */
        {
          path: '/faultAlarm/temControlValveLast',
          name: 'temControlValveLastFault',
          component: () => import(/* webpackChunkName: "temControlValveLastFault" */'@/views/faultAlarm/temControlValve/lastFaultAlarm.vue'),
          meta: {
            keepAlive: true,
            title: 'temControlValveLastFault',
            icon: 'data',
            parent: '/faultAlarm',
            subMenu: 'temControlValve'
          }
        },
        /** 温控阀故障 */
        {
          path: '/faultAlarm/temControlValve',
          name: 'temControlValveFault',
          component: () => import(/* webpackChunkName: "temControlValveFault" */'@/views/faultAlarm/temControlValve'),
          meta: {
            keepAlive: true,
            title: 'temControlValveFault',
            icon: 'data',
            parent: '/faultAlarm',
            subMenu: 'temControlValve'
          }
        },
        /** 平衡阀最新故障 */
        {
          path: '/faultAlarm/balanceValveLast',
          name: 'balanceValveLastFault',
          component: () => import(/* webpackChunkName: "balanceValveLastFault" */'@/views/faultAlarm/balanceValve/lastFaultAlarm.vue'),
          meta: {
            keepAlive: true,
            title: 'balanceValveLastFault',
            icon: 'data',
            parent: '/faultAlarm',
            subMenu: 'balanceValve'
          }
        },
        /** 平衡阀故障 */
        {
          path: '/faultAlarm/balanceValve',
          name: 'balanceValveFault',
          component: () => import(/* webpackChunkName: "balanceValveFault" */'@/views/faultAlarm/balanceValve'),
          meta: {
            keepAlive: true,
            title: 'balanceValveFault',
            icon: 'data',
            parent: '/faultAlarm',
            subMenu: 'balanceValve'
          }
        },
        /** NB 温显器最新故障 */
        {
          path: '/faultAlarm/nbThermometerLast',
          name: 'nbThermometerLastFault',
          component: () => import(/* webpackChunkName: "nbThermometerLastFault" */'@/views/faultAlarm/nbThermometer/lastFaultAlarm.vue'),
          meta: {
            keepAlive: true,
            title: 'nbThermometerLastFault',
            icon: 'data',
            parent: '/faultAlarm',
            subMenu: 'nbThermometer'
          }
        },
        /** NB 温显器故障 */
        {
          path: '/faultAlarm/nbThermometer',
          name: 'nbThermometerFault',
          component: () => import(/* webpackChunkName: "nbThermometerFault" */'@/views/faultAlarm/nbThermometer'),
          meta: {
            keepAlive: true,
            title: 'nbThermometerFault',
            icon: 'data',
            parent: '/faultAlarm',
            subMenu: 'nbThermometer'
          }
        },
        /** 流量计最新故障 */
        {
          path: '/faultAlarm/flowmeterLast',
          name: 'flowmeterLastFault',
          component: () => import(/* webpackChunkName: "flowmeterLastFault" */'@/views/faultAlarm/flowmeter/lastFaultAlarm.vue'),
          meta: {
            keepAlive: true,
            title: 'flowmeterLastFault',
            icon: 'data',
            parent: '/faultAlarm',
            subMenu: 'flowmeter'
          }
        },
        /** 流量计故障 */
        {
          path: '/faultAlarm/flowmeter',
          name: 'flowmeterFault',
          component: () => import(/* webpackChunkName: "flowmeterFault" */'@/views/faultAlarm/flowmeter'),
          meta: {
            keepAlive: true,
            title: 'flowmeterFault',
            icon: 'data',
            parent: '/faultAlarm',
            subMenu: 'flowmeter'
          }
        },
        /** 压力显示器最新故障 */
        {
          path: '/faultAlarm/pressureDisplayLast',
          name: 'pressureDisplayLastFault',
          component: () => import(/* webpackChunkName: "pressureDisplayLastFault" */'@/views/faultAlarm/pressureDisplay/lastFaultAlarm.vue'),
          meta: {
            keepAlive: true,
            title: 'pressureDisplayLastFault',
            icon: 'data',
            parent: '/faultAlarm',
            subMenu: 'pressureDisplay'
          }
        },
        /** 压力显示器故障 */
        {
          path: '/faultAlarm/pressureDisplay',
          name: 'pressureDisplayFault',
          component: () => import(/* webpackChunkName: "pressureDisplayFault" */'@/views/faultAlarm/pressureDisplay'),
          meta: {
            keepAlive: true,
            title: 'pressureDisplayFault',
            icon: 'data',
            parent: '/faultAlarm',
            subMenu: 'pressureDisplay'
          }
        },

      ]
    }
  ]
}