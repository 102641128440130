// 过滤路由表，生成结构化路由表


import * as path from "path";
import { useStore } from "vuex";
import router from "@/router";
import store from "@/store";
import { isObject, object } from "underscore";

/**
 * 所有的子集路由
 */
const getChildrenRoutes = routes => {
  const result = []
  routes.forEach(route => {
    if (route.children && route.children.length > 0) {
      result.push(...route.children)
    }
  })
  return result
}
/**
 * 处理脱离层级的路由: 某个一级路由为其他子路由，则剔除该一级路由，保留路由层级
 * @param {*} routes router.getRoutes()
 */
export const filterRouters = routes => {
  // 所有的子集路由
  const childrenRoutes = getChildrenRoutes(routes)
  // 根据自己路由进行查重操作
  return routes.filter(route => {
    // 根据 route 在childrenRoutes 中进行查重，把所有的重复路由表剔除
    return !childrenRoutes.find(childrenRoute => {
      return childrenRoute.path === route.path
    })
  })
}

/**
 * 判断数据是否为空值
 */
function isNull(data) {
  if (!data) return true
  if (JSON.stringify(data) === '{}') return true
  if (JSON.stringify(data) === '[]') return true
  return false
}

/**
 * 根据 routes 数据，返回对应的menu 规格数据
 */
export const generateMenus = (routes, basePath = '') => {
  const result = []
  // 不满足该条件 meta && meta.title && meta.icon 的数据不应该存在
  // 遍历理由表
  routes.forEach(item => {
    // 不存在 children && 不存在 meta 之间返回
    if (isNull(item.children) && isNull(item.meta)) return
    // 存在 children 不存在 meta 迭代 generateMenus
    if ((isNull(item.meta) || item.path === '/') && !isNull(item.children)) {
      result.push(...generateMenus(item.children))
    } else {
      // 不存在 children 存在 meta
      // 因为最终的menu 需要进行跳转，此时我们需要合并 path
      const routePath = path.resolve(basePath, item.path)
      // 路由分离之后，可能存在 同名父路由的情况
      let route = result.find(item => item.path === routePath)

      // 当前路由尚未加入到 result
      if (!route) {
        route = {
          ...item,
          path: routePath,
          children: []
        }
        // icon && title 存在
        if (route.meta.icon && route.meta.title) {
          result.push(route)
        }
      }
      // 存在 children 进入迭代到 children
      if (item.children) {
        route.children.push(...generateMenus(item.children, route.path))
      }
    }
  })
  return result

}

// 获取当前用户的结构路由
export function getRouters() {
  let userRouters = []
  if (store && store.getters && store.getters.routers) {
    userRouters = store.getters.routers
  }
  if (store && store.getters && store.getters.userId === '') return []
  if (userRouters.length === 0) {
    const filterRoutes = filterRouters(router.getRoutes())
    const menuRoutes = generateMenus(filterRoutes)
    userRouters = menuRoutes
    if (store) {
      store.commit('user/setRouters', menuRoutes)
    }
  }
  return userRouters
}


/**
 * 获取导航栏菜单
 */
export const getNavigationRouter = () => {
  const menuRouters = getRouters()
  let navigationRouters = []
  menuRouters.forEach(item => {
    navigationRouters.push({ path: item.path, name: item.name, index: item.meta.index,item:item })

  })
  navigationRouters.sort((a, b) => a.index - b.index)
  return navigationRouters
}
/**
 * 获取数据子菜单
 */
export const getDataSubMenuRouter = () => {
  const menuRouters = getRouters()
  let dataSubMenuRouters = []
  let subMenuDict = {}
  menuRouters.forEach(item => {
    if (item.name === 'dataManage') {
      item.children.forEach(childItem => {
        if (childItem.meta.subMenu === '') return
        if (!subMenuDict.hasOwnProperty(childItem.meta.subMenu)) {
          subMenuDict[childItem.meta.subMenu] = [
            {
              path: childItem.path,
              title: childItem.meta.title
            }]
        } else {
          subMenuDict[childItem.meta.subMenu].push({
            path: childItem.path,
            title: childItem.meta.title
          })
        }
      })
    }

  })
  for (let key in subMenuDict) {
    dataSubMenuRouters.push({
      title: key,
      children: subMenuDict[key]
    })
  }
  return dataSubMenuRouters
}

/**
 * 通用路由接口
 * @param {*} name 
 * @returns 
 */
const currencyRouter = name => {
  const menuRouters = getRouters()
  let routers = []
  menuRouters.forEach(item => {
    if (item.name === name) {
      // userRouters.push(item)
      item.children.forEach(childItem => {
        if (childItem.path !== item.path) {
          routers.push(childItem)
        }
      })
    }
  })
  return routers
}

/**
 * 获取系统管理子集路由
 */
export const getUserRouter = () => {
  return currencyRouter('sysMain')
}

/**
 * 获取设备管理子集路由
 */

export const getEqptRouter = () => {
  return currencyRouter('basicsManage')
}

/**
 * 获取设备运维子菜单
 * @returns 
 */
export const getEqptOpSubMenuRouter = () => {
  const menuRouters = getRouters()
  let dataSubMenuRouters = []
  let subMenuDict = {}
  menuRouters.forEach(item => {
    if (item.name === 'eqptOpManage') {
      item.children.forEach(childItem => {
        if (!childItem.meta.subMenu){
          subMenuDict[childItem.meta.title] ={title:childItem.meta.title,path:childItem.path} 
        }else{
          if (!subMenuDict.hasOwnProperty(childItem.meta.subMenu)) {
            subMenuDict[childItem.meta.subMenu] = [
              {
                path: childItem.path,
                title: childItem.meta.title
              }]
          } else {
            subMenuDict[childItem.meta.subMenu].push({
              path: childItem.path,
              title: childItem.meta.title
            })
          }
        }
      })
    }

  })
  for (let key in subMenuDict) {
    if(subMenuDict[key] instanceof Array){
      dataSubMenuRouters.push({
        title: key,
        children: subMenuDict[key]
      })
    }else{
      dataSubMenuRouters.push({
        title: key,
        path:subMenuDict[key].path
      })
    }
  }
  return dataSubMenuRouters
  // return currencyRouter('eqptOpManage')
}

export const getFaultAlarmTouter = () => {
  return getSecondaryRoute('faultAlarmManage')
}
/**
 * 获取二级路由 横向路由
 * @param {*} routeName   路由名
 * @returns 
 */
export const getSecondaryRoute = (routeName) => {
  const menuRouters = getRouters()
  let dataSubMenuRouters = []
  let subMenuDict = {}
  menuRouters.forEach(item => {
    if (item.name === routeName) {
      item.children.forEach(childItem => {
        if (!childItem.meta.subMenu){
          subMenuDict[childItem.meta.title] ={title:childItem.meta.title,path:childItem.path} 
        }else{
          if (!subMenuDict.hasOwnProperty(childItem.meta.subMenu)) {
            subMenuDict[childItem.meta.subMenu] = [
              {
                path: childItem.path,
                title: childItem.meta.title
              }]
          } else {
            subMenuDict[childItem.meta.subMenu].push({
              path: childItem.path,
              title: childItem.meta.title
            })
          }
        }
      })
    }

  })
  for (let key in subMenuDict) {
    if(subMenuDict[key] instanceof Array){
      dataSubMenuRouters.push({
        title: key,
        children: subMenuDict[key]
      })
    }else{
      dataSubMenuRouters.push({
        title: key,
        path:subMenuDict[key].path
      })
    }
  }
  return dataSubMenuRouters
}