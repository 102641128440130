import {axiosPost, axiosPostDownload} from '@/utils/request'
import axios from "axios";
import {getNodeTypeIdStr, joinComma} from "@/utils/stringutil";
import store from "@/store/index"
import {getUserCompanyStr} from "@/utils/cache";
import { reject } from 'underscore';
/**
 * 数据相关操作
 */

/**
 * 获取组织树
 */
export const getSimpleTreeNode = data => {
  return axiosPost({
    url: '/nodeInfo/getSimpleTreeNode',
    data: data,
    loading: false
  })
}
/**
 * 获取用户的数据树
 */
export const getSimpleTreeNodeByPermission = nodeIds => {
  return axiosPost({
    url: '/nodeInfo/getSimpleTreeNodeByPermission',
    data:{
      nodeIds: nodeIds
    },
    loading: false
  })
}
/**
 * 通过id 获取子节点
 */
export const getChild = data => {
  return axiosPost({
    url: `/nodeInfo/getChildNode`,
    data: data,
    loading: false
  })
}

// 设置搜索的用户节点
const setSelectId = data => {
  // 无监听数据直接返回
  if (data.watchData === null || data.watchData === undefined || data.watchData.nodeId === undefined){
    data.companyUnitId = '123456789qwerasdzxc' // 设置为无法查询出数据的条件
  } else {
    data[getNodeTypeIdStr(data.watchData.nodeType)] = data.watchData.nodeId
  }
  delete data.watchData
}
// 设置自定义搜索条件
const setSearchType = data => {
  for (const key in data.searchParams) {
    if (data.searchParams[key] instanceof Array){
      key.split(',').forEach((keyItem,keyIndex) => {
        data[keyItem] = data.searchParams[key][keyIndex]
      })
    }else{
      data[key] = data.searchParams[key]
    }

  }
  delete data.searchParams
}

// 设置数据
export const setData = data => {
  setSelectId(data)
  setSearchType(data)
  delete data.parentRequestParams
  delete data.watchData
  // const permissionList = []
  // store.getters.loginInfo.userDataPermissions.forEach(item => {
  //   if (item.permissionType === 'node') {
  //     permissionList.push(item.relId)
  //   }
  // })
  // data.nodeIds = joinComma('',permissionList)

}

// 数据查询
export const selectData = (data, url, autoFillCompanyId = true, uniqueRequest = false) => {
  setData(data)
  return new Promise(async (resolve, reject) => {
    if (data.companyUnitId === "123456789qwerasdzxc") {
      resolve({
        total: 0,
        data:[],
      })
    } else {
      if (autoFillCompanyId && !data.companyId){
        const companyId = await getUserCompanyStr()
        data.companyId = companyId
      }
      axiosPost({
        url: url,
        data: data,
        uniqueRequest: uniqueRequest,
        loading: false
      }).then(data => {
        resolve({
          total: data.pageParam.totleInfo,
          data: data.data
        })
      }).catch(err => {
        reject(err)
      })
    }

  })
}

/**
 * 查询表最新数据
 */
export const getMeterReadingLastListByPage = data => {
  return selectData(data,'/meterReadingLast/getMeterReadingLastListByPage', uniqueRequest=false)
}
/**
 * 获取历史数据
 * @param data
 * @returns {Promise<unknown>}
 */
export const getMeterReadingListByPage = data => {
  return selectData(data,'/meterReading/getMeterReadingListByPage', uniqueRequest=false)
}

/**
 * 获取阀控履历
 * @param {*} data 
 * @returns 
 */
export const getCmdInfoListByNodeId = data => {
  return new Promise(async (resolve, reject) => {
    if (!data.companyId){
      const companyId = await getUserCompanyStr()
      data.companyId = companyId
    }
    axiosPost({
      url: '/equipmentCmdInfo/getCmdInfoListByNodeId',
      data: data,
      loading: false
    }).then(data => {
      resolve(data)
    }).catch(err => {
      reject(err)
    })
})

}

/**
 * 获取异常信息,通过节点id
 * @param data
 * @returns {Promise<unknown>}
 */
export const selectExceptionInforListByNodeId = data => {
  return selectData(data,'/eqptExceptionInfo/selectExceptionInfoListByNodeId')
}

/**
 * 获取异常信息列表，通过设备id
 * @param data
 * @returns {Promise<unknown>}
 */
export const selectExceptionInforListByEqptId = data => {
  return axiosPost({
    url: '/eqptExceptionInfo/selectExceptionInfoListByEqptId',
    data: data,
    loading: false
  })
}



/** 获取一个水表的历史数据
 */
export const getMeterReadingListByEqptId = async data => {
  if (!data.companyId){
    const companyId = await getUserCompanyStr()
    data.companyId = companyId
  }
  return axiosPost({
    url: '/meterReading/getMeterReadingListByPage',
    data: data,
    loading: false
  })
}


/**
 * 通过设备id 获取 echarts 数据 运行数据
 * @param data
 * @returns {Promise<unknown>}
 */
export const getEchartsDataByEqptId = data => {
  return axiosPost({
    url: '/meterReading/countDailyWaterConsumption',
    data: data,
    loading: false
  })
}

/**
 * 导出数据
 * @param data
 */
export const exportData = data => {
  axiosPostDownload({
    url: '/commonExportExcel/exportExcelByRecord',
    data: data,
  })
}

// 导出所有数据
export const exportAllData = data => {
  axiosPostDownload({
    url: '/commonExportExcel/exportExcelByUrl',
    data: data,
  })
}

// 获取批次号
export const getEqptProductionBatch = async data => {
  const companyId = await getUserCompanyStr()
  return axiosPost({
    url: '/nodeEqptRel/gourpByProductionBatch',
    data: {
      companyId: companyId,
      ...data
    },
    loading: false,
    uniqueRequest: true,
  })
}

/**
 * 查询设备最新参数接口
 * @param {Object} data 
 * @returns 
 */
export const getEquipmentCmdParamsInfo = data => {
  return axiosPost({
    url: "/equipmentCmdParams/getEquipmentCmdParamsInfo",
    data: data,
    loading: false
  })
}

export const getCollectorCmdInfoList = data => {
  return new Promise(async (resolve, reject) => {
    if (!data.sort) {
      delete data.sort
    } 
    axiosPost({
      url: '/collectorInfo/getCollectorCmdInfoList',
      data: data,
      uniqueRequest: true,
      loading: false
    }).then(data => {
      resolve(data)
    }).catch(err => {
      reject(err)
    })
  })
}

// 用热最新数据
export const selectUseHeatDataLast = data => {
  return axiosPost({
    url: '/meterReadingLast/selectUseHeatDataLast',
    data: data,
    loading: true,
    tips: false,
  })
}
// 用热最新数据Lindorm
export const selectUseHeatDataByPermission = data => {
  return axiosPost({
    url: '/meterReadingLast/selectUseHeatDataByPermission',
    data: data,
    loading: true,
    tips: false,
  })
}
// 用热历史数据
export const selectUseHeatData = data => {
  return axiosPost({
    url: '/meterReading/selectUseHeatData',
    data: data,
    loading: true,
    tips: false,
  })
}
// 用热最新数据Lindorm
export const selectUseHeatHisDataByPermission = data => {
  return axiosPost({
    url: '/meterReading/selectUseHeatDataByPermission',
    data: data,
    loading: true,
    tips: false,
  })
}
// 用热配置查询
export const selectUseHeatProcessConf = data => {
  return axiosPost({
    url: '/useHeatProcessConf/selectUseHeatProcessConf',
    data: data,
    loading: true,
    tips: false,
  })
}
// 用热配置新增
export const insertUseHeatProcessConfBatch = data => {
  return axiosPost({
    url: '/useHeatProcessConf/insertUseHeatProcessConfBatch',
    data: data,
    loading: true,
    tips: true,
  })
}
// 用热配置修改
export const updateUseHeatProcessConf = data => {
  return axiosPost({
    url: '/useHeatProcessConf/updateUseHeatProcessConfBatch',
    data: data,
    loading: true,
    tips: true,
  })
}
// 用热配置删除
export const deleteUseHeatProcessConf = data => {
  return axiosPost({
    url: '/useHeatProcessConf/deleteUseHeatProcessConfBatch',
    data: data,
    loading: true,
    tips: true,
  })
}